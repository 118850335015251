import React, { useRef, useState } from 'react';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './components';
import SoftwareUpdates from './SoftwareUpdates';
import AdminLocation from './Location';
import { TabIndex } from './type';
import { theme } from '../../styles/theme';
import Calendar from './Calendar';

const AdminSettings: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TabIndex>(TabIndex.LOCATION);
  const tabMenuRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleSwitchTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!tabMenuRef.current) return;

    setIsDragging(true);
    setStartX(e.pageX - tabMenuRef.current.offsetLeft);
    setScrollLeft(tabMenuRef.current.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !tabMenuRef.current) return;

    const x = e.pageX - tabMenuRef.current.offsetLeft;
    const walk = (x - startX) * 2; // How fast to scroll
    tabMenuRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Wrapper>
      <Content>
        <TabMenu
          ref={tabMenuRef}
          value={tab}
          onChange={handleSwitchTab}
          variant="scrollable"
          scrollButtons="auto"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp} // Stop dragging if mouse leaves the area
        >
          <ReportTab label={t('admin_settings.tab.location')} />
          <ReportTab label={t('admin_settings.tab.software_updates')} />
          <ReportTab label={t('Database')} />
          <ReportTab label={t('Processor')} />
          <ReportTab label={t('Alarm')} />
          <ReportTab label={t('Maintenance')} />
          <ReportTab label={t('Client')} />
          <ReportTab label={t('Accounts')} />
          <ReportTab label={t('Emergency Team')} />
          <ReportTab label={t('Recreation')} />
          <ReportTab label={t('Digital Reception')} />
          <ReportTab label={t('Temporary badges')} />
          <ReportTab label={t('admin_settings.tab.calendar')} />
        </TabMenu>
        <TabPanel value={tab} index={TabIndex.SOFTWARE_UPDATES}>
          <SoftwareUpdates />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.LOCATION}>
          <AdminLocation />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.TEMPORARY_BADGES}>
          <AdminLocation />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.CALENDAR}>
          <Calendar />
        </TabPanel>
      </Content>
    </Wrapper>
  );
};

export default AdminSettings;

const { common, grey } = theme.palette;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${common.white};
`;

const Content = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TabMenu = styled(Tabs)`
  width: 100%;
  border-bottom: 1px solid ${grey[400]};
  cursor: grab; /* Cursor verandert in handje wanneer je de muis erover beweegt */
  user-select: none; /* Voorkomt dat tekst wordt geselecteerd terwijl je sleept */

  &.dragging {
    cursor: grabbing; /* Cursor verandert in grijpend handje tijdens slepen */
  }
`;

const ReportTab = styled(Tab)`
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  color: ${grey[300]};
`;
