import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dimensions } from '../../../../../styles/dimensions';
import { EMPTY_VALUE, TagActiveStatus } from '../../../types';
import { ReactComponent as Activated } from '../../../../../assets/image/Users/Activated.svg';
import { AccessInfo } from '../../type';
import { QRCodeView } from './QRCodeView';
import { theme } from '../../../../../styles/theme';

type Props = {
  accessInfo: AccessInfo;
};

export const AccessInformation: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { startDate, endDate, tagActive, tagNumber, tagText, pin, email } = props.accessInfo;

  const displayTagNumber = tagNumber && tagNumber.length > 15 ? `${tagNumber.slice(0, 15)}...` : tagNumber;

  const tagActiveStatus =
    tagActive === TagActiveStatus.Activated ? (
      <TagWrapper>
        <ValueWrapper variant="body1">{t(`visitor.tag_status.activated`)}</ValueWrapper>
        <CustomActivated />
      </TagWrapper>
    ) : (
      <ValueWrapper variant="body1">{EMPTY_VALUE}</ValueWrapper>
    );

  return (
    <Box display="flex" flexDirection="column" rowGap={baseMD}>
      <Typography variant="subtitle3" color="grey.100">
        {t('visitor.access_information')}
      </Typography>

      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.start_date_time')}</PropertyWrapper>
        <ValueWrapper variant="body1">{startDate || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.end_date_time')}</PropertyWrapper>
        <ValueWrapper variant="body1">{endDate || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.tag_active')}</PropertyWrapper>
        {tagActiveStatus}
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.tag_number')}</PropertyWrapper>
        <ValueWrapper variant="body1" title={tagNumber}>
          {displayTagNumber || EMPTY_VALUE}
        </ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.tag_text')}</PropertyWrapper>
        <ValueWrapper variant="body1">{tagText || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <QRCodeWrapper>
        <PropertyWrapper variant="body1">{t('visitor.qr_code')}</PropertyWrapper>
        <QRCodeView tagNumber={tagNumber} email={email} />
      </QRCodeWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.pin')}</PropertyWrapper>
        <ValueWrapper variant="body1">{pin ? pin.replace(/./g, '*') : EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
    </Box>
  );
};

const { baseSM, baseMD } = dimensions;

const {
  shadows,
  palette: { grey }
} = theme;

const ItemWrapper = styled(Box)`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${baseSM};
  box-shadow: ${shadows[4]};
`;

const PropertyWrapper = styled(Typography)`
  width: 150px;
  padding: ${baseSM} ${baseSM} ${baseSM} 0px;
  color: ${grey[200]};
`;

const ValueWrapper = styled(Typography)`
  white-space: pre;
  padding: ${baseSM} ${baseSM} ${baseSM} ${baseMD};
  color: ${grey[100]};
`;

const TagWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const QRCodeWrapper = styled(ItemWrapper)`
  height: 96px;
  align-items: flex-start;
`;

const CustomActivated = styled(Activated)`
  margin-top: ${baseSM};
`;
