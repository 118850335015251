import { Box, InputLabel, styled, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../assets/image/Copy.svg';
import QRCodeLogo from '../../../../assets/image/QRCodeLogo.png';
import DefaultAvatar from '../../../../assets/image/Users/DefaultAvatar.jpg';
import { SecondaryButton } from '../../../../components/Button';
import { CheckboxInput } from '../../../../components/Input';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { EMAIL_REGEX } from '../../../../utils/regex';
import { showErrorToast } from '../../../../components/Toast/actions';

type Props = {
  tagNumber: string;
  email: string;
  onGenerateNewQRCode?: () => void;
  label?: string;
  required?: boolean;
};

export const QRCodeForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { tagNumber, label, required, email } = props;

  const copyQRCode = (): void => {
    if (!tagNumber) return;
    const canvas = document.getElementById('qrCodeImage') as HTMLCanvasElement;

    if (!canvas) return;
    canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob! })]));
    tagNumber && window.navigator.clipboard.writeText(tagNumber);
  };

  const handleSendEmailClick = () => {
    if (!isValidEmail(email)) {
      showErrorToast({
        title: t('visitor.no_email_title'),
        subtitle: t('visitor.no_email_message')
      });
      return;
    }

    // Add the logic to send the email here if needed
  };

  const isValidEmail = (email: string) => {
    return email && EMAIL_REGEX.test(email);
  };

  return (
    <QRCodeValueWrapper>
      {label && (
        <Label required={required} shrink={false}>
          {label}
        </Label>
      )}
      <Box display="flex" flexDirection="row" columnGap={baseSM}>
        <QRCodeImageWrapper>
          {tagNumber ? (
            <QRCode
              id="qrCodeImage"
              value={tagNumber}
              renderAs="canvas"
              size={80}
              imageSettings={{ src: QRCodeLogo, width: 16, height: 19, excavate: false }}
            />
          ) : (
            <Box
              component="img"
              src={DefaultAvatar}
              alt={t('qr_code_image')}
              marginRight={10}
              sx={{ width: '62px', height: '62px' }}
            />
          )}
        </QRCodeImageWrapper>
        <ButtonWrapper rowGap={baseSM}>
          <QRCodeButton startIcon={<CopyIcon />} onClick={copyQRCode} disabled={!tagNumber}>
            <Typography textTransform="initial" variant="body3" color="primary">
              {t('visitor.copy_qr_code')}
            </Typography>
          </QRCodeButton>

          <SendEmailTextWrapper>
            <StyledCheckbox name="isSendEmail" disabled={!isValidEmail(email)} />
            <Box onClick={handleSendEmailClick}>{t('visitor.send_email_to_visitor')}</Box>
          </SendEmailTextWrapper>
        </ButtonWrapper>
      </Box>
    </QRCodeValueWrapper>
  );
};

const { base, baseXS, baseSM, baseLG } = dimensions;
const { error, grey } = theme.palette;

const QRCodeValueWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QRCodeImageWrapper = styled(Box)`
  width: 80px;
  height: 80px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const QRCodeButton = styled(SecondaryButton)`
  width: fit-content;
  height: ${baseLG};
  padding: ${baseXS} ${base};
  justify-content: flex-start;
`;

const SendEmailTextWrapper = styled(Box)`
  width: 150px;
  display: flex;
  flex-direction: row;
  column-gap: ${baseXS};
  width: auto;
  align-items: center;
`;

const StyledCheckbox = styled(CheckboxInput)`
  padding: 0;
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  width: 36%;
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
