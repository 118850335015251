import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  IconButton
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../styles/theme';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { GridTable } from '../../Users/Group/components';
import { TableAction } from '../../Users/Group/Edit/types';
import { DoorAuthorization as DoorAuthorizationType } from '../../../models';
import { useUsersHook } from '../../Users/store';
import EditIcon from '@mui/icons-material/Edit';
import { useCalendarStore } from './store';

type DoorStatus = 'permanent_open' | 'permanent_closed';

const Calendar: React.FC = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showDoorStatus, setShowDoorStatus] = useState(false);
  const [doorStatus, setDoorStatus] = useState<DoorStatus>('permanent_closed');
  const [note, setNote] = useState<string>('');
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  // States voor deuren selectie
  const [usedDoors, setUsedDoors] = useState<DoorAuthorizationType[]>([]);
  const [notUsedDoors, setNotUsedDoors] = useState<DoorAuthorizationType[]>([]);
  const [{ existedDoors }, { getExistedDoors }] = useUsersHook();
  const [{ isLoading, error }, { scheduleCalendar }] = useCalendarStore();

  useEffect(() => {
    getExistedDoors();
  }, []);

  useEffect(() => {
    if (existedDoors) {
      setNotUsedDoors(existedDoors);
    }
  }, [existedDoors]);

  const handleAddDoor = (door: DoorAuthorizationType) => {
    setUsedDoors([...usedDoors, door]);
    setNotUsedDoors(notUsedDoors.filter(d => d.doorId !== door.doorId));
  };

  const handleRemoveDoor = (door: DoorAuthorizationType) => {
    setNotUsedDoors([...notUsedDoors, door]);
    setUsedDoors(usedDoors.filter(d => d.doorId !== door.doorId));
  };

  const handleStartDateChange = (date: any) => {
    if (date instanceof Date || date === null) {
      setStartDate(date);
      if (endDate && date && date > endDate) {
        setEndDate(null);
      }
    }
  };

  const handleEndDateChange = (date: any) => {
    if (date instanceof Date || date === null) {
      setEndDate(date);
    }
  };

  const handleContinue = () => {
    if (startDate && endDate && startTime && endTime) {
      setDialogOpen(true);
    }
  };

  const handleConfirm = () => {
    setDialogOpen(false);
    setShowDoorStatus(true);
  };

  const handleSaveSettings = () => {
    // Hier komt de logica voor het opslaan van de instellingen
    console.log({
      startDate,
      endDate,
      startTime,
      endTime,
      doorStatus,
      usedDoors
    });
  };

  const handleSaveClick = () => {
    setShowSummaryDialog(true);
  };

  const handleConfirmSave = async () => {
    if (!startDate || !endDate || !startTime || !endTime) return;

    // Combineer datum en tijd
    const startDateTime = new Date(startDate);
    startDateTime.setHours(startTime.getHours(), startTime.getMinutes());

    const endDateTime = new Date(endDate);
    endDateTime.setHours(endTime.getHours(), endTime.getMinutes());

    const success = await scheduleCalendar({
      doorIds: usedDoors.map(door => door.doorId),
      doorStatus,
      startDateTime,
      endDateTime,
      comment: note
    });

    if (success) {
      setShowSummaryDialog(false);
      // Optioneel: toon success message
      // Optioneel: reset form
    }
  };

  const handleEditDateTime = () => {
    setShowDoorStatus(false);
  };

  if (showDoorStatus) {
    return (
      <Wrapper>
        <ListWrapper>
          <Header>
            <Typography variant="subtitle4" textTransform="uppercase" color="grey.100">
              {t('admin_settings.calendar.title')}
            </Typography>
          </Header>
        </ListWrapper>

        <ContentWrapper>
          <FullHeightContent>
            <DateTimeCard>
              <DateTimeHeader>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarMonthIcon sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="h6" color="textPrimary">
                    {t('admin_settings.calendar.selected_range')}
                  </Typography>
                </Box>
                <IconButton onClick={handleEditDateTime} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </DateTimeHeader>
              <DateTimeContent>
                <DateTimeRow>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t('admin_settings.calendar.from')}:
                  </Typography>
                  <Typography variant="body1">
                    {startDate?.toLocaleDateString()} {startTime?.toLocaleTimeString()}
                  </Typography>
                </DateTimeRow>
                <DateTimeRow>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t('admin_settings.calendar.to')}:
                  </Typography>
                  <Typography variant="body1">
                    {endDate?.toLocaleDateString()} {endTime?.toLocaleTimeString()}
                  </Typography>
                </DateTimeRow>
              </DateTimeContent>
            </DateTimeCard>

            <StatusContent>
              <RadioGroupWrapper>
                <StyledFormControlLabel
                  value="permanent_open"
                  control={
                    <Radio
                      checked={doorStatus === 'permanent_open'}
                      onChange={e => setDoorStatus(e.target.value as DoorStatus)}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.primary.main
                        }
                      }}
                    />
                  }
                  label={
                    <RadioLabel>
                      <Typography variant="h6">{t('admin_settings.calendar.door_status.permanent_open')}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('admin_settings.calendar.door_status.permanent_open_description')}
                      </Typography>
                    </RadioLabel>
                  }
                />
                <StyledFormControlLabel
                  value="permanent_closed"
                  control={
                    <Radio
                      checked={doorStatus === 'permanent_closed'}
                      onChange={e => setDoorStatus(e.target.value as DoorStatus)}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.primary.main
                        }
                      }}
                    />
                  }
                  label={
                    <RadioLabel>
                      <Typography variant="h6">{t('admin_settings.calendar.door_status.permanent_closed')}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('admin_settings.calendar.door_status.permanent_closed_description')}
                      </Typography>
                    </RadioLabel>
                  }
                />
              </RadioGroupWrapper>

              <TablesWrapper>
                <TableContainer>
                  <GridTable
                    header={t('admin_settings.calendar.door_status.selected_doors')}
                    titles={[
                      t('group.door_authorization.column.door'),
                      t('group.door_authorization.column.location'),
                      t('group.action')
                    ]}
                    rows={usedDoors.map(({ doorName, doorLocation }) => ({
                      doorName,
                      doorLocation
                    }))}
                    actionType={TableAction.REMOVE}
                    action={row => {
                      const door = usedDoors.find(
                        d => d.doorName === row.doorName && d.doorLocation === row.doorLocation
                      );
                      if (door) handleRemoveDoor(door);
                    }}
                    placeholder={t('admin_settings.calendar.door_status.no_doors_selected')}
                  />
                </TableContainer>

                <TableContainer>
                  <GridTable
                    header={t('admin_settings.calendar.door_status.available_doors')}
                    titles={[
                      t('group.door_authorization.column.door'),
                      t('group.door_authorization.column.location'),
                      t('group.action')
                    ]}
                    rows={notUsedDoors.map(({ doorName, doorLocation }) => ({
                      doorName,
                      doorLocation
                    }))}
                    actionType={TableAction.ADD}
                    action={row => {
                      const door = notUsedDoors.find(
                        d => d.doorName === row.doorName && d.doorLocation === row.doorLocation
                      );
                      if (door) handleAddDoor(door);
                    }}
                    placeholder={t('admin_settings.calendar.door_status.no_doors_available')}
                  />
                </TableContainer>
              </TablesWrapper>

              <NoteSection>
                <Typography variant="h6" color="textPrimary" mb={1}>
                  {t('admin_settings.calendar.door_status.note')}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={note}
                  onChange={e => setNote(e.target.value)}
                  placeholder={t('admin_settings.calendar.door_status.note_placeholder')}
                />
              </NoteSection>

              <ButtonContainer>
                <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={usedDoors.length === 0}>
                  {t('admin_settings.calendar.door_status.save')}
                </Button>
              </ButtonContainer>
            </StatusContent>

            {/* Summary Dialog */}
            <Dialog open={showSummaryDialog} onClose={() => setShowSummaryDialog(false)} maxWidth="sm" fullWidth>
              <DialogTitle>{t('admin_settings.calendar.summary.title')}</DialogTitle>
              <DialogContent>
                {error && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                  </Typography>
                )}
                <SummaryContent>
                  <SummarySection>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('admin_settings.calendar.summary.period')}
                    </Typography>
                    <Typography>
                      {t('admin_settings.calendar.from')}: {startDate?.toLocaleDateString()}{' '}
                      {startTime?.toLocaleTimeString()}
                    </Typography>
                    <Typography>
                      {t('admin_settings.calendar.to')}: {endDate?.toLocaleDateString()} {endTime?.toLocaleTimeString()}
                    </Typography>
                  </SummarySection>

                  <SummarySection>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('admin_settings.calendar.summary.status')}
                    </Typography>
                    <Typography>{t(`admin_settings.calendar.door_status.${doorStatus}`)}</Typography>
                  </SummarySection>

                  <SummarySection>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('admin_settings.calendar.summary.doors')}
                    </Typography>
                    <Typography>{usedDoors.map(door => door.doorName).join(', ')}</Typography>
                  </SummarySection>

                  {note && (
                    <SummarySection>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('admin_settings.calendar.summary.note')}
                      </Typography>
                      <Typography>{note}</Typography>
                    </SummarySection>
                  )}
                </SummaryContent>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowSummaryDialog(false)} color="inherit" disabled={isLoading}>
                  {t('admin_settings.calendar.cancel')}
                </Button>
                <Button onClick={handleConfirmSave} variant="contained" color="primary" disabled={isLoading}>
                  {isLoading ? t('common.loading') : t('admin_settings.calendar.confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </FullHeightContent>
        </ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ListWrapper>
        <Header>
          <Typography variant="subtitle4" textTransform="uppercase" color="grey.100">
            {t('admin_settings.calendar.title')}
          </Typography>
        </Header>
      </ListWrapper>

      <ContentWrapper>
        <Header>
          <HeaderLeft>
            <CalendarMonthIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
            <Typography variant="h6" color="textPrimary">
              {t('admin_settings.calendar.select_dates')}
            </Typography>
          </HeaderLeft>
        </Header>

        <CalendarContent>
          <CalendarContainer>
            <CalendarBox>
              <Typography variant="h6" mb={2} color="textPrimary">
                {t('admin_settings.calendar.start_date')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledStaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={startDate}
                  onChange={handleStartDateChange}
                  renderInput={params => <TextField {...params} />}
                />
                <TimePickerWrapper>
                  <Typography variant="h6" mb={1} color="textPrimary">
                    {t('admin_settings.calendar.start_time')}
                  </Typography>
                  <TimePicker
                    value={startTime}
                    onChange={setStartTime}
                    ampm={false}
                    renderInput={params => <TextField {...params} />}
                  />
                </TimePickerWrapper>
              </LocalizationProvider>
            </CalendarBox>

            <CalendarBox>
              <Typography variant="h6" mb={2} color="textPrimary">
                {t('admin_settings.calendar.end_date')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledStaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate || undefined}
                  renderInput={params => <TextField {...params} />}
                />
                <TimePickerWrapper>
                  <Typography variant="h6" mb={1} color="textPrimary">
                    {t('admin_settings.calendar.end_time')}
                  </Typography>
                  <TimePicker
                    value={endTime}
                    onChange={setEndTime}
                    ampm={false}
                    renderInput={params => <TextField {...params} />}
                  />
                </TimePickerWrapper>
              </LocalizationProvider>
            </CalendarBox>
          </CalendarContainer>

          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}
              disabled={!startDate || !endDate || !startTime || !endTime}>
              {t('admin_settings.calendar.continue')}
            </Button>
          </ButtonContainer>
        </CalendarContent>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{t('admin_settings.calendar.selected_range')}</DialogTitle>
          <DialogContent>
            <p>
              {t('admin_settings.calendar.from')}: {startDate?.toLocaleDateString()} {startTime?.toLocaleTimeString()}
            </p>
            <p>
              {t('admin_settings.calendar.to')}: {endDate?.toLocaleDateString()} {endTime?.toLocaleTimeString()}
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="secondary">
              {t('admin_settings.calendar.cancel')}
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="contained">
              {t('admin_settings.calendar.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Calendar;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;

const ListWrapper = styled(Box)`
  width: 300px;
  padding: 8px 16px;
  border-radius: 4px;
  height: calc(100% - 8px);
  box-shadow: ${theme.shadows[2]};
  background-color: ${theme.palette.grey[500]};

  ${props => props.theme.breakpoints.down('lg')} {
    width: 200px;
  }
`;

const ContentWrapper = styled(Box)`
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 300px);
  box-shadow: ${theme.shadows[3]};
  background-color: ${theme.palette.common.white};
  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100% - 200px);
  }
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: white;
  border-bottom: 1px solid ${theme.palette.grey[100]};
  height: 48px;
`;

const HeaderLeft = styled(Box)`
  display: flex;
  align-items: center;
`;

const CalendarContent = styled(Box)`
  flex: 1;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${theme.palette.grey[500]};
`;

const CalendarContainer = styled(Box)`
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`;

const CalendarBox = styled(Box)`
  background-color: ${theme.palette.common.white};
  padding: 24px;
  border-radius: 8px;
  box-shadow: ${theme.shadows[1]};
  text-align: center;

  .MuiPickersCalendarHeader-root {
    margin-top: 0;
  }
`;

const StyledStaticDatePicker = styled(StaticDatePicker)`
  background-color: ${theme.palette.common.white};
  border-radius: 8px;
  overflow: hidden;

  .MuiPickersDay-root {
    &:hover {
      background-color: ${theme.palette.primary.light};
    }
    &.Mui-selected {
      background-color: ${theme.palette.primary.main};
    }
  }

  .MuiTextField-root {
    display: none;
  }
`;

const TimePickerWrapper = styled(Box)`
  margin-top: 16px;
  padding: 16px;
  background-color: ${theme.palette.common.white};
  border-radius: 8px;

  .MuiTextField-root {
    width: 100%;
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(3)};
  padding: ${theme.spacing(2)};
`;

const FullHeightContent = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SelectedDateTimeInfo = styled(Box)`
  margin-bottom: ${theme.spacing(3)};
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.common.white};
  border-radius: 8px;
`;

const StatusContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.common.white};
  border-radius: 8px;
  padding: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};
`;

const TablesWrapper = styled(Box)`
  display: flex;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(3)};
  flex: 1;
  overflow: auto;
`;

const TableContainer = styled(Box)`
  flex: 1;
  min-width: 435px;
  height: 100%;
`;

const DateTimeCard = styled(Box)`
  background-color: ${theme.palette.common.white};
  border-radius: 8px;
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(3)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

const DateTimeHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.palette.grey[100]};
`;

const DateTimeContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const DateTimeRow = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

const RadioGroupWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(3)};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: ${theme.spacing(2)};
  border-radius: 8px;
  border: 1px solid ${theme.palette.grey[200]};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${theme.palette.grey[50]};
  }

  &.Mui-checked {
    border-color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.light};
  }
`;

const RadioLabel = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(0.5)};
`;

const NoteSection = styled(Box)`
  margin-top: ${theme.spacing(3)};
`;

const SummaryContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  margin: ${theme.spacing(2)} 0;
`;

const SummarySection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;
