import React, { useState, useEffect, useRef } from 'react';
import { theme } from '../../../styles/theme';
import { Box, styled, IconButton, Divider, TextField } from '@mui/material';
import { ReactComponent as EntradaLogo } from '../../../assets/image/EntradaSide.svg';
import { ReactComponent as Schild } from '../../../assets/image/Schild.svg';
import { dimensions } from '../../../styles/dimensions';
import ChristmasOverlaySVG from '../../../assets/image/ChristmasOverlay.svg';
import LanguageSelectorLogin from '../../../components/LanguageSelectorLogin';
import { useTranslation } from 'react-i18next';
import LogoEntradaWithShield from '../../../assets/image/LogoEntrada_Diap+schildje.png';
import { Fireworks } from 'fireworks-js';

type Props = {
  children: JSX.Element;
};

const { common } = theme.palette;
const { base5XL, baseSM } = dimensions;

const LogoContainer = styled(Box)`
  position: absolute;
  left: 50%;
  top: calc(50% - 360px);
  transform: translateX(-50%);
  z-index: 4;
  width: 441px;
  height: 89px;
  margin-top: -60px;
  opacity: 1;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SmallLogo = styled(Box)`
  width: 47px;
  height: 53px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
`;

const BackgroundImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 1;
`;

export const MainLayout: React.FunctionComponent<Props> = ({ children }) => {
  const [showChristmas] = useState(true);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const fireworksRef = useRef<Fireworks | null>(null);

  useEffect(() => {
    if (containerRef.current && showChristmas) {
      const fireworks = new Fireworks(containerRef.current, {
        autoresize: true,
        opacity: 1,
        acceleration: 1.05,
        friction: 0.97,
        gravity: 1.5,
        particles: 50,
        traceLength: 3,
        traceSpeed: 10,
        explosion: 5,
        intensity: 30,
        flickering: 50,
        lineStyle: 'round',
        hue: {
          min: 0,
          max: 360
        },
        delay: {
          min: 30,
          max: 60
        }
      });

      fireworksRef.current = fireworks;

      const timer = setTimeout(() => {
        fireworks.start();
      }, 1000);

      return () => {
        clearTimeout(timer);
        fireworksRef.current?.stop();
      };
    }
  }, [showChristmas]);

  return (
    <Wrapper>
      {showChristmas && (
        <>
          <BackgroundOverlay>
            <BackgroundImage src={ChristmasOverlaySVG} alt="Christmas overlay" />
          </BackgroundOverlay>
          <div
            ref={containerRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              zIndex: 5
            }}
          />
        </>
      )}
      <LogoContainer>
        <img src={LogoEntradaWithShield} alt="Entrada Security Systems" />
      </LogoContainer>
      <ContentContainer>{children}</ContentContainer>
      <BottomBar>
        <LanguageSelectorLogin />
        <ContactLink href="https://entradasecurity.com/contact/" target="_blank" rel="noopener noreferrer">
          Contact
        </ContactLink>
      </BottomBar>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #698223;
  position: relative;
  overflow: hidden;
`;

const BackgroundLogo = styled(EntradaLogo)`
  position: absolute;
  width: 90vh;
  height: 90vh;
  opacity: 0.15;
  fill: #698223;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const ContentContainer = styled(Box)`
  background: ${common.white};
  border-radius: 8px;
  padding: ${base5XL}px;
  padding-top: ${Number(base5XL) * 1.6}px;
  padding-bottom: ${Number(base5XL) * 1.3}px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 460px;
  height: 450px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

const BottomBar = styled(Box)`
  position: absolute;
  top: calc(50% + 225px + 5px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  z-index: 4;
`;

const ContactLink = styled('a')`
  color: ${common.white};
  text-decoration: underline;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
