import { styled, Typography, InputLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { $get } from '../../../../utils/http';
import { dimensions } from '../../../../styles/dimensions';
import { CheckboxInput, DateInput, TextInput } from '../../../../components/Input';
import { TimePicker } from '../../../../components/TimePicker';
import { PrimaryButton } from '../../../../components/Button';
import React from 'react';
import { QRCodeForm } from './QRCodeForm';
import { theme } from '../../../../styles/theme';
import dayjs, { Dayjs } from 'dayjs';
import { DEFAULT_START_DATE } from '../../types';
import { useUsersHook } from '../../store';
import { FormActionType, VisitorInfo } from '../type';

type Props = {
  email: string;
  formActionType: FormActionType;
};

interface GenerateResponse {
  UID: string;
}

export const AccessInformationForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { email, formActionType } = props;

  const [{ visitorInfo }] = useUsersHook();

  const initTagNumber = formActionType === FormActionType.EDIT ? visitorInfo?.tagNumber : '';

  const [inputTagNumber, setInputTagNumber] = React.useState<string>(initTagNumber ?? '');

  const getPlaceholderText = (value: string): string => value.slice(0, -1);

  // can not make a default value with dayjs like DEFAULT_START_TIME
  const initStartTime = dayjs(visitorInfo?.startDate ?? new Date());

  const initEndTime = dayjs(visitorInfo?.endDate ?? new Date());

  const [tagType, setTagType] = React.useState<'qr' | 'tag'>('qr');

  const getFormInitValue = (): {
    startDate: string;
    startTime: Dayjs;
    endDate: string;
    endTime: Dayjs;
    tagActive: string;
    tagText: string;
    pin: string;
  } => {
    switch (formActionType) {
      case FormActionType.EDIT:
        return {
          startDate: visitorInfo?.startDate ?? '',
          startTime: initStartTime,
          endDate: visitorInfo?.endDate ?? '',
          endTime: initEndTime,
          tagActive: visitorInfo?.tagActive ?? '0',
          tagText: visitorInfo?.tagText ?? '',
          pin: visitorInfo?.pin ?? ''
        };
      case FormActionType.ADD_NEW:
      default:
        return {
          startDate: DEFAULT_START_DATE,
          startTime: initStartTime,
          endDate: DEFAULT_START_DATE,
          endTime: initEndTime,
          tagActive: '1',
          tagText: '',
          pin: ''
        };
    }
  };

  const onTagInputChange = (value: string) => {
    setInputTagNumber(value);
  };

  const generateTagNumber = async () => {
    try {
      const response: GenerateResponse = await $get('user/generate');
      const newTagNumber = response.UID;
      setInputTagNumber(newTagNumber);
    } catch (error) {
      console.error('Error generating tag number:', error);
    }
  };

  return (
    <Wrapper rowGap={baseSM}>
      <Typography variant="subtitle3" color="grey.100" marginBottom={baseMD}>
        {t('visitor.access_information')}
      </Typography>

      <DateTimeWrapper>
        <Label required shrink={false}>
          {t('visitor.start_date_time')}
        </Label>
        <Box display="flex" flexDirection="row">
          <DateInput
            width={DATE_PICKER_WIDTH}
            inputWidth={DATE_INPUT_WIDTH}
            defaultValue={getFormInitValue().startDate}
            minDate={new Date(getFormInitValue().startDate)}
            name="startDate"
            required
          />
          <TimePicker
            defaultValue={getFormInitValue().startTime}
            width={TIME_PICKER_WIDTH}
            height={base2XL}
            name="startTime"
            required
          />
        </Box>
      </DateTimeWrapper>

      <DateTimeWrapper>
        <Label required shrink={false}>
          {t('visitor.end_date_time')}
        </Label>
        <Box display="flex" flexDirection="row">
          <DateInput
            width={DATE_PICKER_WIDTH}
            inputWidth={DATE_INPUT_WIDTH}
            defaultValue={getFormInitValue().endDate}
            minDate={new Date(getFormInitValue().endDate)}
            name="endDate"
            required
          />
          <TimePicker
            defaultValue={getFormInitValue().endTime}
            width={TIME_PICKER_WIDTH}
            height={base2XL}
            name="endTime"
            required
          />
        </Box>
      </DateTimeWrapper>

      <CheckBoxWrapper>
        <Label>{t('visitor.active')}</Label>
        <StyledCheckbox name="tagActive" defaultValue={getFormInitValue().tagActive} />
      </CheckBoxWrapper>

      <InfoWrapper>
        <TextInput
          label={t('visitor.tag_text')}
          placeholder={getPlaceholderText(t('visitor.tag_text'))}
          name="tagText"
          width={TEXT_INPUT_WIDTH}
          defaultValue={getFormInitValue().tagText}
        />
      </InfoWrapper>

      <QRCodeWrapper>
        <Box display="flex" flexDirection="column" gap={baseXS}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Label required>{t('visitor.access_type')}</Label>
            <RadioGroup row value={tagType} onChange={e => setTagType(e.target.value as 'qr' | 'tag')}>
              <FormControlLabel value="qr" control={<Radio />} label={t('visitor.qr_code')} />
              <FormControlLabel value="tag" control={<Radio />} label={t('visitor.tag_number')} />
            </RadioGroup>
          </Box>

          {tagType === 'qr' ? (
            <>
              <input type="hidden" name="tagNumber" value={inputTagNumber} />
              {!inputTagNumber ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <PrimaryButton
                    content={t('visitor.generate_qr_code')}
                    onClick={generateTagNumber}
                    width="auto"
                    type="button"
                  />
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" gap={baseXS}>
                  <QRCodeForm label={t('visitor.qr_code')} tagNumber={inputTagNumber} email={email} />
                  <Box display="flex" justifyContent="flex-end" width="100%" paddingRight={baseXS}>
                    <PrimaryButton
                      content={t('visitor.generate_new_qr_code')}
                      onClick={generateTagNumber}
                      width="auto"
                      type="button"
                    />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <TextInput
              label={t('visitor.tag_number')}
              placeholder={getPlaceholderText(t('visitor.tag_number'))}
              name="tagNumber"
              width={TEXT_INPUT_WIDTH}
              value={inputTagNumber}
              onChange={e => setInputTagNumber(e.target.value)}
            />
          )}
        </Box>
      </QRCodeWrapper>

      <InfoWrapper>
        <TextInput
          label={t('visitor.pin')}
          placeholder={getPlaceholderText(t('visitor.pin'))}
          name="pin"
          width={TEXT_INPUT_WIDTH}
          defaultValue={getFormInitValue().pin}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

const { baseXS, baseSM, baseMD, base2XL } = dimensions;

const { error, grey } = theme.palette;

const TIME_PICKER_WIDTH = '110px';

const DATE_PICKER_WIDTH = '178px';

const DATE_INPUT_WIDTH = '90%';

const TEXT_INPUT_WIDTH = '71%';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const InfoWrapper = styled(Box)`
  height: ${base2XL};
  width: 100%;
`;

const DateTimeWrapper = styled(InfoWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  width: 35%;
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;

const CheckBoxWrapper = styled(InfoWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QRCodeWrapper = styled(InfoWrapper)`
  height: fit-content;
  align-items: flex-start;
  padding: ${baseXS} 0;
`;

const StyledCheckbox = styled(CheckboxInput)`
  padding: 0;
`;
