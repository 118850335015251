import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { theme } from '../styles/theme';
import { dimensions } from '../styles/dimensions';

const { primary } = theme.palette;
const { baseSM } = dimensions;

interface LanguageSelectorProps {
  className?: string;
}

const LanguageSelectorLogin: React.FunctionComponent<LanguageSelectorProps> = ({ className }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState<string>(() => {
    return localStorage.getItem('i18nextLng') || 'en';
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (!storedLanguage) {
      localStorage.setItem('i18nextLng', 'en');
      i18n.changeLanguage('en');
      setLanguage('en');
    } else {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (newLanguage: string) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('i18nextLng', newLanguage);
    handleClose();
  };

  const languages = [
    { code: 'nl', name: 'Nederlands' },
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' }
  ];

  const currentLanguage = languages.find(lang => lang.code === language)?.name || language;

  return (
    <>
      <SelectorText onClick={handleClick} className={className}>
        {currentLanguage}
        <KeyboardArrowDownIcon className="arrow-icon" />
      </SelectorText>

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {languages.map(lang => (
          <MenuItem key={lang.code} onClick={() => handleLanguageSelect(lang.code)} selected={language === lang.code}>
            {lang.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

const SelectorText = styled('div')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 17px;

  .arrow-icon {
    color: white;
    margin-left: 2px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 100px;
    margin-top: 4px;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }

  .MuiMenuItem-root {
    font-size: 17px;
    padding: 6px 12px;
    min-height: 32px;

    &.Mui-selected {
      background-color: ${theme.palette.primary.light};
      color: ${theme.palette.primary.main};
    }

    &:hover {
      background-color: ${theme.palette.primary.light};
    }
  }
`;

export default LanguageSelectorLogin;
