import React, { useState } from 'react';
import {
  Box,
  styled,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Pagination,
  TextField,
  InputAdornment
} from '@mui/material';
import { theme } from '../../../styles/theme';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';

const ANPR: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const camerasPerPage = 6;

  // Dummy camera data (later te vervangen door echte data)
  const allCameras = Array.from({ length: 18 }, (_, i) => ({
    id: i + 1,
    name: `Gate ${i + 1}`
  }));

  // Filter cameras based on search query
  const filteredCameras = allCameras.filter(camera => camera.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const totalPages = Math.ceil(filteredCameras.length / camerasPerPage);
  const currentCameras = filteredCameras.slice((page - 1) * camerasPerPage, page * camerasPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Reset to first page when search query changes
  React.useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
          <DirectionsCarIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
          <Typography variant="h6" color="textPrimary">
            ANPR Camera Feed
          </Typography>
        </HeaderLeft>
        <SearchWrapper>
          <TextField
            size="small"
            placeholder="Search cameras..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
          />
        </SearchWrapper>
      </Header>

      <ContentWrapper>
        <CameraGrid>
          {currentCameras.map(camera => (
            <CameraContainer key={camera.id}>
              <CameraHeader>
                <HeaderInfo>
                  <StatusIndicator />
                  <Typography variant="subtitle2">{camera.name}</Typography>
                </HeaderInfo>
                <Tooltip title="Live View">
                  <IconButton size="small">
                    <VideoCallIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CameraHeader>
              <CameraFeed>
                <PlateInfo>
                  <LicensePlate>XX-123-YY</LicensePlate>
                  <Chip
                    icon={<CheckCircleIcon fontSize="small" />}
                    label="Authorized"
                    size="small"
                    color="success"
                    variant="outlined"
                  />
                </PlateInfo>
              </CameraFeed>
            </CameraContainer>
          ))}
        </CameraGrid>
        <PaginationWrapper>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" size="large" />
        </PaginationWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ANPR;

// Styling van Security tab hergebruiken
const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: white;
  height: 48px;
`;

// ... andere gedeelde styled components zoals in Security ...

// ANPR-specifieke styling
const PlateInfo = styled(Box)`
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LicensePlate = styled(Box)`
  background: ${theme.palette.common.white};
  color: ${theme.palette.common.black};
  padding: 4px 12px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid ${theme.palette.primary.main};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ContentWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
`;

const CameraGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;
  padding: 24px;
  flex: 1;
`;

const CameraContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const CameraHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
`;

const HeaderInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatusIndicator = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #4caf50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
`;

const CameraFeed = styled(Box)`
  aspect-ratio: 16/9;
  background-color: ${theme.palette.grey[900]};
  flex: 1;
  position: relative;
`;

const PaginationWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  background-color: white;
`;

const HeaderLeft = styled(Box)`
  display: flex;
  align-items: center;
`;

const Controls = styled(Box)`
  display: flex;
  gap: 4px;
`;

const SearchWrapper = styled(Box)`
  .MuiTextField-root {
    width: 250px;
    background-color: white;
  }
`;
