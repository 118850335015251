import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Select, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../appContext';
import { dimensions } from '../../styles/dimensions';
import LanguageSelector from '../../components/LanguageSelector';
import LocationSelector from '../../components/LocationSelector';
import { Location, Organization, OrganizationResponse } from '../../models';
import { $get, $put } from '../../utils/http';
import { SelectChangeEvent } from '@mui/material/Select';
import { toast } from 'react-toastify';
import { useAuthAction } from '../../modules/Auth/store';
import { useNavigate } from 'react-router-dom';

const { baseMD } = dimensions;

const MyProfile: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { adminProfile, loadAdminProfile } = useAppContext();
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrgId, setCurrentOrgId] = useState<number | null>(null);
  const [hasOrgChanged, setHasOrgChanged] = useState(false);
  const [initialOrgId, setInitialOrgId] = useState<number | null>(null);
  const [adminId, setAdminId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { logout } = useAuthAction();

  useEffect(() => {
    loadAdminProfile();
    const storedLocations = localStorage.getItem('defaultLocations');
    if (storedLocations) {
      setSelectedLocations(JSON.parse(storedLocations));
    }
    fetchOrganizations();
  }, [loadAdminProfile]);

  const fetchOrganizations = async () => {
    try {
      const response = await $get('admin/orgchange');
      console.log('GET response:', response);
      setOrganizations(response.data);
      setCurrentOrgId(response.currentOrgId);
      setInitialOrgId(response.currentOrgId);
      setAdminId(response.adminId);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const handleLocationChange = (locations: Location[]) => {
    setSelectedLocations(locations);
    localStorage.setItem('defaultLocations', JSON.stringify(locations));
  };

  const handleOrganizationChange = (event: SelectChangeEvent<number>) => {
    const orgId = event.target.value as number;
    setCurrentOrgId(orgId);
    setHasOrgChanged(orgId !== initialOrgId);
  };

  const handleSaveOrganization = async () => {
    const successMessage = t('organization.change.success');

    try {
      await $put('admin/orgchange', {
        orgId: currentOrgId,
        adminId: adminId
      });

      toast.success(successMessage);
      setInitialOrgId(currentOrgId);
      setHasOrgChanged(false);

      await logout();
      navigate('/auth/login', { replace: true });
      navigate(0);
    } catch (error: any) {
      console.error('Error saving organization:', error);
      toast.success(successMessage);
      setInitialOrgId(currentOrgId);
      setHasOrgChanged(false);

      await logout();
      navigate('/auth/login', { replace: true });
      navigate(0);
    }
  };

  return (
    <Box sx={{ padding: baseMD }}>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.name')}:</Label>
        <Value variant="body1">{adminProfile?.fullName}</Value>
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.role')}:</Label>
        <Value variant="body1">{adminProfile?.userRole}</Value>
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.language')}:</Label>
        <StyledLanguageSelector />
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.location')}:</Label>
        <StyledLocationSelector selectedLocations={selectedLocations} onLocationChange={handleLocationChange} />
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.organization')}:</Label>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Select value={currentOrgId || ''} onChange={handleOrganizationChange} sx={{ minWidth: 200 }}>
            {organizations.map(org => (
              <MenuItem key={org.orgId} value={org.orgId}>
                {org.orgName}
              </MenuItem>
            ))}
          </Select>
          {hasOrgChanged && (
            <Button variant="contained" color="primary" onClick={handleSaveOrganization} sx={{ minWidth: 100 }}>
              {t('save')}
            </Button>
          )}
        </Box>
      </ProfileRow>
    </Box>
  );
};

export default MyProfile;

const ProfileRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust this value as needed for spacing between rows */
  & > *:not(:first-child) {
    margin-left: 16px; /* Adjust this value to ensure consistent spacing between elements */
  }
`;

const Label = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 150px; /* Adjust this value as needed for label width */
`;

const Value = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledLanguageSelector = styled(LanguageSelector)`
  min-width: 200px; /* Set the minimum width for the LanguageSelector */
`;

const StyledLocationSelector = styled(LocationSelector)`
  min-width: 200px; /* Set the minimum width for the LocationSelector */
`;
