import { createStore, StoreActionApi, createHook } from 'react-sweet-state';
import { $put } from '../../../utils/http';

type State = {
  isLoading: boolean;
  error: string | null;
};

const initialState: State = {
  isLoading: false,
  error: null
};

type CalendarSchedule = {
  doorIds: string[];
  doorStatus: 'permanent_open' | 'permanent_closed';
  startDateTime: Date;
  endDateTime: Date;
  comment: string;
};

export const actions = {
  scheduleCalendar:
    (schedule: CalendarSchedule) =>
    async ({ setState }: StoreActionApi<State>) => {
      setState({ isLoading: true, error: null });

      try {
        await $put('admin/doorCalendar', {
          doorIds: schedule.doorIds,
          doorStatus: schedule.doorStatus === 'permanent_open' ? 2 : 3, // Using same status codes as door store
          doorStartDateTime: schedule.startDateTime.toISOString(),
          doorEndDateTime: schedule.endDateTime.toISOString(),
          calendarComment: schedule.comment
          // adminId wordt meestal via de backend bepaald uit de JWT token
        });

        setState({ isLoading: false, error: null });
        return true;
      } catch (error) {
        setState({
          isLoading: false,
          error: error instanceof Error ? error.message : 'Unknown error occurred'
        });
        return false;
      }
    }
};

const CalendarStore = createStore({
  initialState,
  actions
});

export const useCalendarStore = createHook(CalendarStore);
