import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { FullscreenConfig, SlideContent } from '../types/FullscreenConfig';

const FullscreenContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow: hidden;
`;

const SlideContainer = styled(Box)<{ $transitioning: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.$transitioning ? 0 : 1)};
`;

const ElementContainer = styled(Box)(
  (props: { $position: { x: number; y: number; width?: number; height?: number } }) => ({
    position: 'absolute',
    left: `${props.$position.x}%`,
    top: `${props.$position.y}%`,
    width: props.$position.width ? `${props.$position.width}%` : 'auto',
    height: props.$position.height ? `${props.$position.height}%` : 'auto'
  })
);

interface Props {
  config: FullscreenConfig;
  emergencyTeamData: any; // Replace with proper type
  onClose: () => void;
}

export const EnhancedFullscreen: React.FC<Props> = ({ config, emergencyTeamData, onClose }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [weatherData, setWeatherData] = useState<any>(null);
  const [trainData, setTrainData] = useState<any>(null);
  const [trafficData, setTrafficData] = useState<any>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentSlideIndex(current => (current + 1) % config.slides.length);
        setTransitioning(false);
      }, 500);
    }, config.globalSettings.defaultDuration * 1000);

    return () => clearInterval(interval);
  }, [config.slides.length, config.globalSettings.defaultDuration]);

  const currentSlide = config.slides[currentSlideIndex];

  return (
    <FullscreenContainer>
      <SlideContainer $transitioning={transitioning} sx={{ backgroundColor: currentSlide.backgroundColor }}>
        {currentSlide.elements.map((element, index) => (
          <ElementContainer key={index} $position={element.position}></ElementContainer>
        ))}
      </SlideContainer>
    </FullscreenContainer>
  );
};
