import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication, AuthenticationResult } from '@azure/msal-browser';
import { useAuthAction } from './store';
import { showErrorToast } from '../../components/Toast/actions';
import { useTranslation } from 'react-i18next';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`,
    redirectUri: window.location.origin + '/auth/microsoft/callback'
  }
};

const MicrosoftAuth: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginWithMicrosoft } = useAuthAction();

  useEffect(() => {
    const msalInstance = new PublicClientApplication(msalConfig);

    const handleMicrosoftAuth = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();

        if (response !== null) {
          // Token ontvangen, nu inloggen op onze backend
          const result = await loginWithMicrosoft(response.accessToken);

          if (result.requiresTwoFactor) {
            navigate('/auth/second-auth');
          } else {
            navigate('/');
          }
        } else {
          // Start het inlogproces
          await msalInstance.loginRedirect({
            scopes: ['user.read', 'email']
          });
        }
      } catch (error) {
        console.error('Microsoft auth error:', error);
        showErrorToast({
          title: t('login.error.microsoft.title'),
          subtitle: t('login.error.microsoft.content')
        });
        navigate('/auth/login');
      }
    };

    handleMicrosoftAuth();
  }, [navigate, loginWithMicrosoft, t]);

  return <div>Loading...</div>;
};

export default MicrosoftAuth;
