export enum ReportLogColumn {
  TYPE = 'Type',
  NAME = 'Name',
  TIME = 'Time',
  MESSAGE = 'Message',
  DOOR = 'Door',
  GROUP_NAME = 'Group Name',
  DOOR_LOCATION = 'Door Location',
  DEPARTMENT = 'Department',
  ROOM = 'Tag Number',
  CAR_PLATE = 'Car Plate'
}

export enum AuthLogColumn {
  NAME = 'Name',
  USER_LOCATION = 'User Location',
  DOOR = 'Door',
  DOOR_LOCATION = 'Door Location',
  DOOR_SECTION = 'Door Section',
  GROUP_NAME = 'Group Name',
  MONDAY = 'Mon',
  TUESDAY = 'Tue',
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
  SUNDAY = 'Sun'
}

export enum RowPosition {
  ODD = 'odd',
  EVEN = 'even'
}

export type ReportField = {
  name: ReportLogColumn;
  display: boolean;
};

export type ReportLogForm = {
  selectedUserIds: string[];
  searchWarnings: boolean;
  searchEmergencyTeam: boolean;
  selectedDoorIds: string[];
  selectedLocationIds: string[];
  startDate: string;
  endDate: string;
  presenceList: string;
  presenceTimeReport: string;
  departmentView: boolean;
  roomView: boolean;
  groupNameView: boolean;
};

export enum ReportLogFormField {
  SELECT_USER_IDS = 'selectedUserIds',
  SEARCH_WARNINGS = 'searchWarnings',
  SEARCH_EMERGENCY_TEAM = 'searchEmergencyTeam',
  SELECT_DOOR_IDS = 'selectedDoorIds',
  SELECT_LOCATION = 'selectedLocationIds',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PRESENCE_LIST = 'presenceList',
  PRESENCE_TIME_REPORT = 'presenceTimeReport',
  DEPARTMENT_VIEW = 'departmentView',
  ROOM_VIEW = 'roomView',
  GROUP_NAME_VIEW = 'groupNameView'
}

export enum TabIndex {
  LOG_HISTORY_REPORT = 0,
  AUTH_REPORT = 1,
  ADMINISTRATOR_ACTIVITY_LOG = 2,
  PRESENCE_LIST = 3,
  PRECENSE_TIME_REPORT = 4,
  DASHBOARD_LOG = 5
}
