import React, { useState } from 'react';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './components/TabPanel';
import Security from './Security/index';
import ANPR from './ANPR/index';
import { TabIndex } from './type';
import { theme } from '../../styles/theme';

const Camera: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TabIndex>(TabIndex.Security);

  const handleSwitchTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Wrapper>
      <Content>
        <TabMenu value={tab} onChange={handleSwitchTab}>
          <ReportTab label={t('camera.tab.security')} />
          <ReportTab label={t('camera.tab.anpr')} />
        </TabMenu>
        <TabPanel value={tab} index={TabIndex.Security}>
          <Security />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.ANPR}>
          <ANPR />
        </TabPanel>
      </Content>
    </Wrapper>
  );
};

export default Camera;

const { common, grey } = theme.palette;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${common.white};
`;

const Content = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TabMenu = styled(Tabs)`
  width: 98%;
  display: flex;
  align-self: center;
  border-bottom: 1px solid ${grey[400]};
`;

const ReportTab = styled(Tab)`
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  color: ${grey[300]};
`;
