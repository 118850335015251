import { Box, styled, Typography, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { ReactComponent as Key } from '../../assets/image/Key.svg';
import { ReactComponent as Person } from '../../assets/image/Person.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/image/microsoft-logo-svgrepo-com.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../appContext';
import { ErrorPopup } from '../../components/Popup';
import { dimensions } from '../../styles/dimensions';
import { isAuthenticated } from '../../utils/storage';
import { useAuthHook } from './store';
import { AuthError, InputFieldType } from './type';
import { LoginInfo, LoginResponse } from '../../models';
import { MainLayout, InputField } from './components';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';

const { primary } = theme.palette;
const { baseSM, baseXL, baseXM, buttonHeightLarge } = dimensions;

interface StyledProps {
  $isMobile?: boolean;
  $isTablet?: boolean;
}

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useAppContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isError, setIsError] = React.useState<boolean>(false);
  const [username, setUserName] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [{ authentication }, { login }] = useAuthHook();
  const [error, setError] = React.useState<AuthError>({
    title: '',
    content: ''
  });

  async function handleLogin(loginInformation: LoginInfo) {
    const { username, password } = loginInformation;

    try {
      showLoading();
      const response: LoginResponse = await login(username, password);
      hideLoading();

      const { twoFaKeySetRequired, twoFaValueExpected, qrImage, secret } = response;

      const encoded = btoa(
        JSON.stringify({
          qrImage: qrImage,
          secret: secret
        })
      );

      if (twoFaKeySetRequired) {
        if (twoFaValueExpected) {
          navigate(`/auth/second-auth/verify/${encoded}`);
        } else {
          navigate(`/auth/second-auth/add-account/${encoded}`);
        }
      } else {
        navigate('/');
      }
    } catch (error) {
      hideLoading();
      toast.error(String(t('login.error.wrong_username_or_password.content')));
    }
  }

  React.useEffect(() => {
    const isSetup2Fa = authentication && authentication.twoFaKeySetRequired;

    if (!isSetup2Fa && isAuthenticated()) {
      navigate('/');
    }
  }, [authentication]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin({ username, password });
    }
  };

  const handleContactClick = (e: React.MouseEvent) => {
    console.log('Contact link clicked');
    // Als de link nog steeds niet werkt, kunnen we het programmatisch forceren:
    // window.open('https://entradasecurity.com/contact/', '_blank');
  };

  return (
    <MainLayout>
      <Box>
        <Content>
          <InputWrapper onKeyDown={handleKeyDown}>
            <InputField
              icon={<Person />}
              type={InputFieldType.TEXT}
              value={username}
              label={t('login.username')}
              placeholder={t('login.input_username')}
              onChange={value => setUserName(value)}
            />

            <InputField
              icon={<Key />}
              type={InputFieldType.PASSWORD}
              value={password}
              label={t('login.password')}
              placeholder={t('login.input_password')}
              onChange={value => setPassword(value)}
            />
          </InputWrapper>

          <ButtonContainer $isMobile={isMobile}>
            <div className="login-row">
              <PrimaryButton
                content={t('login.content')}
                width={isMobile ? '100%' : '50%'}
                height="35px"
                onClick={() => handleLogin({ username, password })}
              />
              <ForgotPasswordLink to="/auth/forgot-password">{t('forgot_password.title')}</ForgotPasswordLink>
            </div>

            <StyledDivider>{t('login.or')}</StyledDivider>

            <SecondaryButton
              content={t('login.microsoft')}
              width="100%"
              height={buttonHeightLarge}
              onClick={() => navigate('/auth/microsoft')}
              startIcon={<MicrosoftIcon />}
            />
          </ButtonContainer>
        </Content>
        <ErrorPopup open={isError} onClose={() => setIsError(false)} title={error.title} content={error.content} />
      </Box>
    </MainLayout>
  );
};

export default Login;

const ForgotPasswordLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  color: ${primary.dark};
  text-align: right;
  width: 50%;
`;

const Content = styled(Box)`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${baseXM};
  padding-bottom: ${baseSM};

  @media (min-height: 541px) {
    padding-top: 8%;
  }
`;

const ButtonContainer = styled(Box)<StyledProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${baseSM};
  align-items: center;
  margin-top: 0;

  .login-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${baseSM};

    ${({ $isMobile }) =>
      $isMobile &&
      `
      flex-direction: column;
    `}
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${baseSM} 0;
  width: 100%;
  text-align: center;

  &::before,
  &::after {
    content: '';
    border-top: 1px solid ${theme.palette.grey[200]};
    width: 45%;
    margin: 0 10px;
    display: inline-block;
    vertical-align: middle;
  }
`;
