import { formatDateTime, TimeFormatType } from '../../utils/date';

export enum TypeModule {
  USER = 'USER',
  VISITOR = 'VISITOR',
  GROUP = 'GROUP'
}

export enum CurrentPathName {
  USER = '/users/user',
  VISITOR = '/users/visitor',
  GROUP = '/users/group'
}

export enum TagActiveStatus {
  Activated = '1',
  Deactivated = '0'
}

export enum Mode {
  EDIT = 'edit'
}

export const BLANK_STRING = '';
export const EMPTY_VALUE = '--';
export const DEFAULT_START_TIME = '00:00';
export const DEFAULT_END_TIME = '23:59';

export const EXCEPTION_ID = -1;
export const INITIAL_VALUE = 0;
export const TAKEN_NUMBER = 30000;
export const DEFAULT_START_DATE = formatDateTime(new Date(), TimeFormatType.MONTH_DATE_YEAR);
export const DEFAULT_FREE_TEXT_LABEL = {
  freeForm1: 'freeText1',
  freeForm2: 'freeText2',
  comment: 'Comment'
};
