import { Box, styled, TableContainer, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTable } from '../../../../components/CustomTable';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { mockLogHistory } from '../mockData'; // Import de mock data
import { $get } from '../../../../utils/http';
import { userInfo } from 'os';
import { UserInfo } from '../../../../models';

// Boolean om te schakelen tussen DEMO data en API data
const useDemoData = false; // Zet dit op false om echte API data te gebruiken

interface LogEntry {
  id: string;
  uid: string;
  name: string;
  userIcon: string;
  time: string;
  date: string;
  doorName: string;
  doorLocation: string;
  doorlocationId: string;
  message: string;
  department: string;
  level: string;
  logLevel: string;
  timeDate: string;
  tagNumber: string;
}

interface LogResponse {
  data: LogEntry[];
}

// Mock API Call (indien gebruik van echte API data)
const fetchLogsFromAPI = async (userId: UserInfo['id']) => {
  try {
    const response: LogResponse = await $get(`log/user?userId=${userId}`);
    const mappedLogs = response.data.map((log: LogEntry) => ({
      ...log,
      dateTimeLog: log.timeDate
    }));
    return mappedLogs;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};

// Mock useUsersHook
const useUsersHook = (userId: string | number) => {
  const [state, setState] = React.useState({
    userLogs: useDemoData ? mockLogHistory : [],
    userInfo: { id: userId, name: '' }
  });

  const getLogsOfUser = async (userId: number) => {
    if (useDemoData) {
      // Als we DEMO data gebruiken, hoeven we niets te doen
      return;
    }
    try {
      const logs = await fetchLogsFromAPI(userId.toString());
      setState(prevState => ({
        ...prevState,
        userLogs: logs
      }));
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  return [state, { getLogsOfUser }] as const;
};

// Functie om de logdata te sorteren op tijd (nieuw naar oud)
const sortLogsByDateTime = (logs: any[]) => {
  return logs.sort((a, b) => {
    // Verander de tijd in een Date object voor correcte vergelijking
    const dateA = new Date(`1970-01-01T${a.dateTimeLog}Z`);
    const dateB = new Date(`1970-01-01T${b.dateTimeLog}Z`);
    return dateB.getTime() - dateA.getTime(); // Sorteren op aflopende tijd
  });
};

interface LogHistoryProps {
  userId: string | number;
}

export const LogHistory: React.FunctionComponent<LogHistoryProps> = ({ userId }) => {
  const { t } = useTranslation();

  const [{ userLogs, userInfo }, { getLogsOfUser }] = useUsersHook(userId);

  enum TableColumnKeys {
    DateTimeLog = 'dateTimeLog', // Update de keys om overeen te komen met de mock data
    DoorName = 'doorName',
    Location = 'doorLocation',
    Message = 'message'
  }

  const headerColumns = [
    {
      dataKey: TableColumnKeys.DateTimeLog,
      fieldName: t('user.log.time')
    },
    {
      dataKey: TableColumnKeys.DoorName,
      fieldName: t('user.log.door_name')
    },
    {
      dataKey: TableColumnKeys.Location,
      fieldName: t('user.log.door_location')
    },
    {
      dataKey: TableColumnKeys.Message,
      fieldName: t('user.log.message')
    }
  ];

  // Sorteer de logs voordat ze worden doorgegeven aan de tabel
  const sortedLogs = sortLogsByDateTime(userLogs);

  React.useEffect(() => {
    if (userInfo && userInfo.id) {
      const numericId = Number(userInfo.id);
      if (!isNaN(numericId)) {
        getLogsOfUser(numericId);
      }
    }
  }, [userInfo, getLogsOfUser]);

  return (
    <Wrapper>
      <Title variant="body1">{t('user.log.title')}</Title>

      <LogContainer>
        <CustomTable columns={headerColumns} dataList={sortedLogs} cellMaxWidth="130px" />
      </LogContainer>
    </Wrapper>
  );
};

const { grey } = theme.palette;
const { base } = dimensions;

const Wrapper = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  overflow-y: auto;
  padding-bottom: ${base};
`;

const LogContainer = styled(TableContainer)`
  height: calc(100% - 48px);
  overflow-y: auto;
`;

const Title = styled(Typography)`
  color: ${grey[100]};
  margin: ${base} 0;
`;
