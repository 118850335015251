import React, { useState, useEffect } from 'react';
import { ReactComponent as Employee } from '../../../../assets/image/user.svg';
import { ReactComponent as Visitor } from '../../../../assets/image/visitor.svg';
import { ReactComponent as Unknown } from '../../../../assets/image/unknown.svg';
import { ReactComponent as System } from '../../../../assets/image/system.svg';
import { theme } from '../../../../styles/theme';
import { dimensions } from '../../../../styles/dimensions';
import { $get } from '../../../../utils/http';
import {
  TableHead,
  styled,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Table,
  Box,
  Fade,
  Menu,
  MenuItem
} from '@mui/material';
import BodyTableCell from './BodyTableCell';
import { SecondaryButton } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { LogLevel, UserIcon } from '../../../../models';
import { CellAlignment } from '../../../../types/cell';
import { ReportLogColumn, RowPosition } from '../../types';

// Configuratievariabele om highlights in of uit te schakelen
const HIGHLIGHTS_ENABLED = false; // Zet dit op true om highlights in te schakelen, of false om ze uit te schakelen

const getRowLevelColor = (logLevel: LogLevel, position?: RowPosition) => {
  const { error, warning, action, common } = theme.palette;
  switch (logLevel) {
    case LogLevel.NORMAL:
      return position === RowPosition.ODD ? action.hover : common.white;
    case LogLevel.MEDIUM:
      return warning.light;
    case LogLevel.HIGH:
    default:
      return error.light;
  }
};

const renderUserTypeIcon = (type: UserIcon) => {
  switch (type) {
    case UserIcon.EMPLOYEE:
      return <Employee />;
    case UserIcon.VISITOR:
      return <Visitor />;
    case UserIcon.SYSTEM:
      return <System />;
    case UserIcon.UNKNOWN:
    default:
      return <Unknown />;
  }
};

const getRouteDomainByUserIcon = (userIcon: UserIcon, userId: string | null) => {
  if (!userId) return;
  switch (userIcon) {
    case UserIcon.EMPLOYEE:
    case UserIcon.VISITOR:
      return `/users/${userId}`;
    default:
      return;
  }
};

const parseTimeToDate = (timeStr: string): Date => {
  const today = new Date();
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes, seconds);
};

interface LogData {
  id: string;
  uid: string;
  name: string;
  userIcon: UserIcon;
  time: string;
  date: string;
  doorName: string;
  doorLocation: string | null;
  doorlocationId: string | null;
  message: string;
  department: string;
  level: string;
  logLevel: LogLevel;
  timeDate: string;
  tagNumber: string;
}

interface LogResponse {
  data: {
    id: string;
    uid: string;
    name: string;
    userIcon: string;
    time: string;
    date: string;
    doorName: string;
    doorLocation: string | null;
    doorlocationId: string | null;
    message: string;
    department: string;
    level: string;
    logLevel: string;
    timeDate: string;
    tagNumber: string;
  }[];
  code: string;
  message: string | null;
  total: number;
  fromId: number;
  take: number;
  doorLocations: null;
}

interface FilterValues {
  userId?: string;
  locationId?: string;
  startDate?: string;
  endDate?: string;
}

interface GridTableProps {
  filters: FilterValues | null;
}

const GridTable: React.FunctionComponent<GridTableProps> = ({ filters }) => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<LogData[]>([]);
  const [loading, setLoading] = useState(true);
  const [highlightsEnabled, setHighlightsEnabled] = useState(HIGHLIGHTS_ENABLED);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    userId: string;
  } | null>(null);

  // Helper functie om string naar UserIcon te converteren
  const mapToUserIcon = (icon: string): UserIcon => {
    switch (icon.toUpperCase()) {
      case 'EMPLOYEE':
        return UserIcon.EMPLOYEE;
      case 'VISITOR':
        return UserIcon.VISITOR;
      case 'SYSTEM':
        return UserIcon.SYSTEM;
      case 'ACCESS':
      case 'UNKNOWN':
      default:
        return UserIcon.UNKNOWN;
    }
  };

  useEffect(() => {
    if (filters) {
      console.log('Filters changed:', filters); // Debug logging
      handleFilterChange(filters);
    } else {
      fetchLogs();
    }
  }, [filters]);

  const fetchLogs = async () => {
    try {
      const response: LogResponse = await $get('log/user');
      const mappedLogs = response.data.map((log: LogResponse['data'][0]) => ({
        ...log,
        logLevel: mapToLogLevel(log.logLevel),
        userIcon: mapToUserIcon(log.userIcon) // Map de userIcon string naar UserIcon enum
      }));
      setLogs(mappedLogs);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLoading(false);
    }
  };

  // Helper functie om string naar LogLevel te converteren
  const mapToLogLevel = (level: string): LogLevel => {
    switch (level.toUpperCase()) {
      case 'HIGH':
        return LogLevel.HIGH;
      case 'MEDIUM':
        return LogLevel.MEDIUM;
      case 'NORMAL':
      default:
        return LogLevel.NORMAL;
    }
  };

  // Sort logs by time (newest first)
  const sortedLogs = React.useMemo(() => {
    return [...logs].sort((a, b) => {
      const dateA = new Date(a.timeDate);
      const dateB = new Date(b.timeDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [logs]);

  const handleFilterChange = (filters: FilterValues) => {
    const fetchFilteredLogs = async () => {
      try {
        const response: LogResponse = await $get('log/user');
        const mappedLogs = response.data.map((log: LogResponse['data'][0]) => ({
          ...log,
          logLevel: mapToLogLevel(log.logLevel),
          userIcon: mapToUserIcon(log.userIcon)
        }));

        // Filter de logs
        const filteredLogs = mappedLogs.filter(log => {
          const matchesUser = !filters.userId || log.uid === filters.userId;
          const matchesLocation = !filters.locationId || log.doorlocationId === filters.locationId;

          if (filters.startDate || filters.endDate) {
            const logDate = new Date(log.timeDate);
            const startDate = filters.startDate ? new Date(filters.startDate) : null;
            const endDate = filters.endDate ? new Date(filters.endDate) : null;

            const matchesDate = (!startDate || logDate >= startDate) && (!endDate || logDate <= endDate);

            return matchesUser && matchesLocation && matchesDate;
          }

          return matchesUser && matchesLocation;
        });

        setLogs(filteredLogs);
      } catch (error) {
        console.error('Error fetching filtered logs:', error);
      }
    };

    fetchFilteredLogs();
  };

  const handleContextMenu = (event: React.MouseEvent, userId: string) => {
    event.preventDefault();
    if (userId) {
      setContextMenu({
        mouseX: event.clientX,
        mouseY: event.clientY,
        userId
      });
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleOpenUser = () => {
    if (contextMenu?.userId) {
      window.open(`/users/user/${contextMenu.userId}`, '_blank');
      handleClose();
    }
  };

  return (
    <Transition in={true}>
      <Wrapper>
        <Header>
          <Typography variant="subtitle1" color="grey.100">
            {t('report.content')}
          </Typography>
          <SecondaryButton content={t('report.export')} height={buttonHeight} />
        </Header>
        <Container>
          <Table stickyHeader>
            <TableHeader>
              <TableRow>
                {Object.values(ReportLogColumn).map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant="subtitle2" color="grey.100">
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <Body>
              {!loading &&
                sortedLogs.map(log => (
                  <Row
                    key={log.id}
                    status={log.logLevel}
                    highlightsEnabled={highlightsEnabled}
                    onContextMenu={e => handleContextMenu(e, log.uid)}
                    style={{ cursor: log.uid ? 'context-menu' : 'default' }}>
                    <BodyTableCell value={renderUserTypeIcon(log.userIcon)} align={CellAlignment.CENTER} />
                    <BodyTableCell value={log.name} link={getRouteDomainByUserIcon(log.userIcon, log.uid)} />
                    <BodyTableCell value={log.time} />
                    <BodyTableCell value={log.message} />
                    <BodyTableCell value={log.doorName} />
                    <BodyTableCell value={log.department} />
                    <BodyTableCell value={log.doorLocation || ''} />
                    <BodyTableCell value={log.department} />
                    <BodyTableCell value={log.tagNumber} />
                    <BodyTableCell value={''} />
                  </Row>
                ))}
            </Body>
          </Table>
        </Container>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
          <MenuItem onClick={handleOpenUser}>{t('open_user')}</MenuItem>
        </Menu>
      </Wrapper>
    </Transition>
  );
};

export default GridTable;

const { common, grey } = theme.palette;
const { inputHeight, buttonHeight } = dimensions;

type TableRowProps = {
  status: LogLevel | undefined;
  highlightsEnabled: boolean;
};

const Transition = styled(Fade)`
  transition-delay: 200ms;
`;

const Wrapper = styled(Box)`
  display: flex;
  height: calc(100% - 170px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
`;

const Header = styled(Box)`
  height: 56px;
  width: 98%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableHeader = styled(TableHead)`
  height: ${inputHeight};
  background: ${common.white};
  border: 1px solid ${grey[400]};
  font-size: 16px;
  font-weight: 700;
`;

const Container = styled(TableContainer)`
  height: calc(100% - 47px);
  overflow: auto;
  border-top: 1px solid ${grey[400]};
`;

const Row = styled(TableRow)<TableRowProps>(({ status, highlightsEnabled }) => ({
  position: 'relative',
  zIndex: '0',
  backgroundColor: highlightsEnabled ? (status ? getRowLevelColor(status) : 'transparent') : 'transparent',
  '&:nth-of-type(odd)': {
    backgroundColor: highlightsEnabled
      ? status
        ? getRowLevelColor(status, RowPosition.ODD)
        : 'transparent'
      : 'transparent'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const Body = styled(TableBody)`
  position: relative;
  overflow: auto;
`;
