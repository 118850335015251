import React, { useState } from 'react';

import { TableCell, tableCellClasses, styled, Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { CellAlignment } from '../../../../types/cell';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  align?: CellAlignment;
  value: JSX.Element | string;
  width?: string;
  link?: string | null;
  isIcon?: boolean;
};

const BodyTableCell: React.FunctionComponent<Props> = (props: Props) => {
  const { className, align, value, width, link, isIcon } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    if (isIcon && link) {
      event.preventDefault();
      setContextMenu({
        mouseX: event.clientX,
        mouseY: event.clientY
      });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleOpenUser = () => {
    if (link) {
      navigate(link);
      handleCloseContextMenu();
    }
  };

  return (
    <Cell
      className={className}
      align={align}
      minwidth={width}
      onContextMenu={handleContextMenu}
      sx={{ cursor: isIcon && link ? 'context-menu' : 'default' }}>
      {link && !isIcon ? <StyledLink to={link}>{value}</StyledLink> : value}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
        <MenuItem onClick={handleOpenUser}>{t('open_user')}</MenuItem>
      </Menu>
    </Cell>
  );
};

export default BodyTableCell;

const { grey, primary } = theme.palette;

const { baseMD, inputHeight } = dimensions;

const StyledLink = styled(Link)`
  color: ${primary.dark};
  font-size: 14px;
  font-weight: 600;
`;

const Cell = styled(TableCell)(({ minwidth }: { minwidth: string | undefined }) => ({
  height: inputHeight,
  padding: `0 ${baseMD}`,
  minWidth: minwidth ? minwidth : 'auto',

  [`&.${tableCellClasses.head}`]: {
    color: grey[100]
  }
}));
