import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useRef, useState, createContext, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FileSelector from '../../../components/FileSelector';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { FileExtension } from '../../../types/file';
import { downloadCSVFile, exportCSV, importCSV, isCSVFile } from '../../../utils/fileHelper';
import { startCaseKeysObj } from '../../../utils/object';
import { importUserConverter, exportUserConverter } from '../converter';

import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { OptionButton, OptionButtonType } from '../../../components/Button/OptionButton';
import { ResponsiveInfinityScroll } from '../../../components/ResponsiveInfinityScroll';
import SearchBar from '../../../components/SearchBar';
import { LocationRouteEndpoints } from '../../../types/locationRouteEndpoints';
import { ButtonWrapper, Header, Item } from '../components';
import { ListWrapper } from '../components/ListWrapper';

import { useUsersHook } from '../store';
import { BLANK_STRING, INITIAL_VALUE } from '../types';
import { EXPORT_USER_FILE_NAME } from './types';

type Props = {
  id: string;
};

const UserListContext = createContext({
  refreshUserList: () => {
    // TODO: Implementeer de logica voor refreshUserList
  }
});

export const useUserListContext = () => useContext(UserListContext);

interface UserListProviderProps {
  children: React.ReactNode;
}

export const UserListProvider: React.FC<UserListProviderProps> = ({ children }) => {
  const refreshUserList = useCallback(() => {
    // TODO: Implementeer de logica voor refreshUserList
  }, []);

  return <UserListContext.Provider value={{ refreshUserList }}>{children}</UserListContext.Provider>;
};

const UserList: React.FunctionComponent<Props> = ({ id }: Props) => {
  const { t } = useTranslation();

  const [{ userList, totalUsers }, actions] = useUsersHook();
  const navigate = useNavigate();
  const location = useLocation();

  const disappearButtons =
    location.pathname.includes(LocationRouteEndpoints.ADD_NEW) ||
    location.pathname.includes(LocationRouteEndpoints.EDIT);

  const { showLoading, hideLoading } = useAppContext();

  const [keyword, setKeyword] = useState<string>(BLANK_STRING);
  const [onSearchMode, setOnSearchMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleSearch = async (value: string) => {
    setKeyword(value);
    setLoading(true);
    try {
      if (value.trim() === BLANK_STRING) {
        await actions.getAllUsers();
      } else {
        await actions.getUsersByName(value);
        setOnSearchMode(true);
      }
    } catch (error) {
      actions.setInitialUserList();
    } finally {
      setLoading(false);
    }
  };

  const handleScrollDown = async () => {
    if (totalUsers > userList.length && userList[userList.length - 1]) {
      const fromId = userList[userList.length - 1].id;
      if (onSearchMode) {
        await actions.getUsersByName(keyword, fromId);
      } else {
        await actions.getAllUsers(fromId);
      }
    }
  };

  const handleChooseEmployee = async (value: string) => {
    navigate(`/users/user/${value}`);
  };

  const navigateToCreateUserPage = () => {
    navigate('/users/user/add-new');
  };

  async function getAllUsers() {
    setLoading(true);
    await actions.getAllUsers();
    setLoading(false);
    setOnSearchMode(false);
    setLoading(false);
  }

  const handleSelectedFile = async (inputFile: File): Promise<void> => {
    showLoading();
    try {
      // Lees het CSV-bestand in en converteer naar ImportUserInfo[]
      const fileData = await importCSV(inputFile);

      // Stuur de geconverteerde data door naar createUserInfoFromFile
      await actions.createUserInfoFromFile(fileData);

      // Toon succesbericht
      showSuccessToast({
        title: t('user.import_user_toast.success.title'),
        subtitle: t('user.import_user_toast.success.subtitle')
      });
    } catch (err) {
      const { message } = err as any;
      // Toon foutmelding
      showErrorToast({
        title: t('user.import_user_toast.failed.title'),
        subtitle: t(message)
      });
    } finally {
      hideLoading();
    }
  };

  async function exportUserList(): Promise<void> {
    showLoading();
    const startCaseDataList: { [key: string]: string }[] = [];
    try {
      const exportUserInfoList = await actions.getExportUserInfoList();
      exportUserInfoList.forEach(info => {
        const convertInfo = exportUserConverter(info);
        startCaseDataList.push(startCaseKeysObj(convertInfo));
      });

      const csvData = exportCSV(startCaseDataList);
      downloadCSVFile(csvData, EXPORT_USER_FILE_NAME);
      showSuccessToast({
        title: t('user.export_user_toast.success.title'),
        subtitle: t('user.export_user_toast.success.subtitle')
      });
    } catch (error) {
      showErrorToast({
        title: t('user.export_user_toast.failed.title'),
        subtitle: t('user.export_user_toast.failed.subtitle')
      });
    } finally {
      hideLoading();
    }
  }

  React.useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (userList.length > 0 && !id && !location.pathname.includes('add-new')) {
      navigate(`/users/user/${userList[0].userId}`);
    }
  }, [userList, id, navigate, location]);

  const displayTotalUsers = React.useMemo(() => {
    if (totalUsers) return totalUsers;
    return userList.length || INITIAL_VALUE;
  }, [totalUsers, userList]);

  return (
    <ListWrapper>
      <Header>
        <Typography variant="subtitle4" color="grey.100">
          {t('user.list')} ({displayTotalUsers})
        </Typography>
      </Header>

      {!disappearButtons && (
        <ButtonWrapper>
          <OptionButton
            type={OptionButtonType.EXPORT}
            content={t('export')}
            isscaledown={true}
            onClick={exportUserList}
          />
          <OptionButton
            type={OptionButtonType.IMPORT}
            content={t('import')}
            isscaledown={true}
            onClick={() => inputFileRef.current?.click()}
          />
          <OptionButton
            type={OptionButtonType.ADD_NEW}
            content={t('add_new')}
            isscaledown={true}
            onClick={navigateToCreateUserPage}
          />
        </ButtonWrapper>
      )}

      <FileSelector
        inputFileRef={inputFileRef}
        onFileSelected={(file: File) => handleSelectedFile(file)}
        fileExts={[FileExtension.CSV]}
      />

      <Box sx={{ width: '100%', maxWidth: 500, mb: 1 }}>
        <SearchBar
          onClear={getAllUsers}
          onSearch={handleSearch}
          placeHolder={t('user.search_user_or_visitor')}
          keyword={keyword}
          onKeywordChanged={setKeyword}
        />
      </Box>

      {!loading && isEmpty(userList) ? (
        <Typography variant="subtitle1" color="grey.200">
          {t('search_no_result')}
        </Typography>
      ) : (
        <ResponsiveInfinityScroll
          height="40vh"
          dataLength={userList ? userList.length : 0}
          next={handleScrollDown}
          hasMore={loading || (userList && totalUsers > userList.length)}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" size={40} />
            </Box>
          }>
          {!isEmpty(userList) &&
            userList.map((user, index) => (
              <Fade in={true} key={index}>
                <Item
                  className={id === user.userId ? 'active' : BLANK_STRING}
                  onClick={() => handleChooseEmployee(user.userId)}>
                  {user.name}
                </Item>
              </Fade>
            ))}
        </ResponsiveInfinityScroll>
      )}
    </ListWrapper>
  );
};

export default UserList;
